import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/builds/finbackoffice/site/packages/mobile/src/app/[lang]/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/layouts/AccountLayout.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/layouts/CasinoLayout.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/layouts/MainLayout.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/layouts/MainLayoutComponents.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/layouts/SportLiveLayout.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/layouts/SportLiveLayoutMain.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/layouts/SportResultsLayout.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/layouts/SportUpcomingLayout.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/screens/Page.tsx");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/styles/normalize.sass");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/styles/common-variables.sass");
;
import(/* webpackMode: "eager" */ "/builds/finbackoffice/site/packages/mobile/src/styles/base.sass");
